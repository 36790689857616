import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthguardService } from './Portal/Service/authguard.service';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboards/dashboard2',
        pathMatch: 'full',
        canActivate: [AuthguardService]
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule',
        canActivate: [AuthguardService],
        // redirectTo: 'dashboards/dashboard2'
      },
      {
        path: 'reportmanager',
        loadChildren: './Portal/Report/report.module#ReportModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'workmanager',
        loadChildren: './Portal/Workmanager/workmanager.module#WorkManagerModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'mastermanager',
        loadChildren: './Portal/Master/master.module#MasterModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'billmanager',
        loadChildren: './Portal/Bill/bill.module#BillModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'complaintmanager',
        loadChildren: './Portal/Complaintmanager/complaint.module#ComplaintModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'material',
        loadChildren:
          './material-component/material.module#MaterialComponentsModule'
      },
      {
        path: 'usermanager',
        loadChildren:
          './Portal/User/user.module#UserModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'creditmanager',
        loadChildren:
          './Portal/Creditmanager/creditmanager.module#CreditmanagerModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'workassign',
        loadChildren:
          './Portal/Workmanager/workmanager.module#WorkManagerModule',
        canActivate: [AuthguardService]
      },
      {
        path: 'setting',
        loadChildren:
          './Portal/Workmanager/workmanager.module#WorkManagerModule',
        canActivate: [AuthguardService]
      },
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },//Reditrect All the apis
  {
    path: 'dashboard2',
    redirectTo: 'dashboards/dashboard2'
  },
  {
    path: 'billmanager/billdepositreport',
    redirectTo: 'reportmanager/billdeposit'
  },
  {
    path: 'billmanager/amazonreport',
    redirectTo: 'reportmanager/amazonreport'
  },
  {
    path: 'billmanager/billreversalreport',
    redirectTo: 'reportmanager/billreversal'
  },
  {
    path: 'creditmanager/rechargereport',
    redirectTo: 'reportmanager/creditreport'
  },
  {
    path: 'workassign/workstatusreport',
    redirectTo: 'reportmanager/userwork'
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
