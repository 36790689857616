import { CommonService } from './../../Service/common.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-billfetch',
  templateUrl: './billfetch.component.html',
  styleUrls: ['./billfetch.component.css']
})
export class BillfetchComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BillfetchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public common: CommonService) {
    this.lists.response = {};
    this.lists.response.billerInputParams = {};
  }
  lists: any = {};
  ngOnInit() {
  }

  GetBillinfo() {
    this.common.PostMethod("SearchBBPS", { billerId: this.lists.billerid }).then((res: any) => {
      console.log(res);
      this.lists.response = res.Message.biller;
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  Billdeposit() {

  }

  Paynow() {
    let ev: any = {};
    ev.receipt = this.lists.RecieptNO;
    ev.datetime = new Date();
    this.common.ReceiptBBPS(ev, '');
  }

}
