import { MenufilterPipe } from './../../Portal/Pipes/menufilter.pipe';
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}
const SuperAdmin = [
  {
    state: '',
    name: 'Menu',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'dashboard2',
    name: 'Dashboard',
    type: 'link',
    icon: 'home',
  },
  {
    state: 'billmanager',
    name: 'Bill Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'billdeposit', name: 'Bill Deposit', type: 'link' },
      { state: 'billdepositreport', name: 'Bill Deposit Report', type: 'link' },
      // { state: 'billreversal', name: 'Bill Reversal', type: 'link' },
      { state: 'billreversalreport', name: 'Reversal Bill Report', type: 'link' },
    ]
  },
  {
    state: 'complaintmanager',
    name: 'Complaint Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'complaint', name: 'Raise Complaint', type: 'link' },
    ]
  },
  {
    state: 'creditmanager',
    name: 'Credit Manager',
    type: 'sub',
    icon: 'credit_card',
    children: [
      { state: 'accountrecharge', name: 'Account Recharge', type: 'link' },
      { state: 'rechargereport', name: 'Recharge History', type: 'link' },
      { state: 'myrecharge', name: 'My Account Recharge', type: 'link' },

    ]
  },
  {
    state: 'mastermanager',
    name: 'Master Manager',
    type: 'sub',
    icon: 'desktop_windows',
    children: [
      { state: 'mastermanager', name: 'Branch Manager', type: 'link' },
    ]
  },
  {
    state: 'reportmanager',
    name: 'Report Manager',
    type: 'sub',
    icon: 'report',
    children: [
      { state: 'amazonreport', name: 'Amazon Deposit Report', type: 'link' },

      { state: 'billdeposit', name: 'Bill Deposit Report', type: 'link' },
      { state: 'billreversal', name: 'Bill Reversal Report', type: 'link' },
      { state: 'collection', name: 'Collection Report', type: 'link' },
      { state: 'creditreport', name: 'Credit Report', type: 'link' },
      { state: 'reversedreport', name: 'Reversed Bill Report', type: 'link' },
      { state: 'usercollection', name: 'User Collection Report', type: 'link' },
      { state: 'userwork', name: 'User Work Report', type: 'link' },
      { state: 'payu', name: 'Payu Transaction Report', type: 'link' },
    ]
  },
  {
    state: 'usermanager',
    name: 'User Manager',
    type: 'sub',
    icon: 'supervised_user_circle',
    children: [
      { state: 'createuser', name: 'Create User', type: 'link' },
      { state: 'admintransfer', name: 'Agent Transfer', type: 'link' },
    ]
  },
  {
    state: 'workassign',
    name: 'Work Manager',
    type: 'sub',
    icon: 'work',
    children: [
      { state: 'workassign', name: 'Work Assignment Manager', type: 'link' },
      { state: 'workstatusreport', name: 'Work Status Report', type: 'link' },
      { state: 'mywork', name: 'My Work', type: 'link' },
      { state: 'setting', name: 'Setting', type: 'link' },


    ]
  },
];
const Admin = [
  {
    state: '',
    name: 'Menu',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'dashboard2',
    name: 'Dashboard',
    type: 'link',
    icon: 'home',
  },
  {
    state: 'billmanager',
    name: 'Bill Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'billdeposit', name: 'Bill Deposit', type: 'link' },
      { state: 'billdepositreport', name: 'Bill Deposit Report', type: 'link' },
    ]
  },
  {
    state: 'complaintmanager',
    name: 'Complaint Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'complaint', name: 'Raise Complaint', type: 'link' },
    ]
  },
  {
    state: 'creditmanager',
    name: 'Credit Manager',
    type: 'sub',
    icon: 'credit_card',
    children: [
      { state: 'accountrecharge', name: 'Account Recharge', type: 'link' },
      { state: 'rechargereport', name: 'Recharge History', type: 'link' },
      { state: 'myrecharge', name: 'My Account Recharge', type: 'link' },

    ]
  },
  {
    state: 'mastermanager',
    name: 'Master Manager',
    type: 'sub',
    icon: 'desktop_windows',
    children: [
      { state: 'mastermanager', name: 'Branch Manager', type: 'link' },
    ]
  },
  {
    state: 'reportmanager',
    name: 'Report Manager',
    type: 'sub',
    icon: 'report',
    children: [
      { state: 'amazonreport', name: 'Amazon Deposit Report', type: 'link' },

      { state: 'billdeposit', name: 'Bill Deposit Report', type: 'link' },
      { state: 'collection', name: 'Collection Report', type: 'link' },
      { state: 'creditreport', name: 'Credit Report', type: 'link' },
      { state: 'usercollection', name: 'User Collection Report', type: 'link' },
    ]
  },
  {
    state: 'usermanager',
    name: 'User Manager',
    type: 'sub',
    icon: 'supervised_user_circle',
    children: [
      { state: 'createuser', name: 'Create User', type: 'link' },
    ]
  },

];
const Agent = [
  {
    state: '',
    name: 'Menu',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'dashboard2',
    name: 'Dashboard',
    type: 'link',
    icon: 'home',
  },
  {
    state: 'billmanager',
    name: 'Bill Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'billdeposit', name: 'Bill Deposit', type: 'link' },
      { state: 'billdepositreport', name: 'Bill Deposit Report', type: 'link' },
    ]
  },
  {
    state: 'complaintmanager',
    name: 'Complaint Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'complaint', name: 'Raise Complaint', type: 'link' },
    ]
  },
  {
    state: 'creditmanager',
    name: 'Credit Manager',
    type: 'sub',
    icon: 'credit_card',
    children: [
      { state: 'rechargereport', name: 'My Recharge History', type: 'link' },
      { state: 'myrecharge', name: 'My Account Recharge', type: 'link' },
      { state: 'myrecharge', name: 'My Account Recharge', type: 'link' },
    ]
  },
  {
    state: 'reportmanager',
    name: 'Report Manager',
    type: 'sub',
    icon: 'report',
    children: [
      { state: 'amazonreport', name: 'Amazon Deposit Report', type: 'link' },

      { state: 'billdeposit', name: 'Bill Deposit Report', type: 'link' },
      { state: 'creditreport', name: 'Credit Report', type: 'link' },

    ]
  },

];
const Depositor = [
  {
    state: '',
    name: 'Menu',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'dashboard2',
    name: 'Dashboard',
    type: 'link',
    icon: 'home',
  },
  {
    state: 'reportmanager',
    name: 'Report Manager',
    type: 'sub',
    icon: 'report',
    children: [
      { state: 'userwork', name: 'My Work Report', type: 'link' },
    ]
  },
  {
    state: 'workassign',
    name: 'Work Manager',
    type: 'sub',
    icon: 'work',
    children: [
      { state: 'workstatusreport', name: 'Work Status Report', type: 'link' },
      { state: 'mywork', name: 'My Work', type: 'link' },
      { state: 'setting', name: 'Setting', type: 'link' },
    ]
  },
];


const MENUITEMS = [
  {
    state: '',
    name: 'Menu',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'dashboard2',
    name: 'Dashboard',
    type: 'link',
    icon: 'home',
  },
  {
    state: 'billmanager',
    name: 'Bill Manager',
    type: 'sub',
    icon: 'receipt',
    children: [
      { state: 'billdeposit', name: 'Bill Deposit', type: 'link' },
      { state: 'billdepositreport', name: 'Bill Deposit Report', type: 'link' },
      { state: 'billreversal', name: 'Bill Reversal', type: 'link' },
      { state: 'billreversalreport', name: 'Reversal Bill Report', type: 'link' },
    ]
  },
  {
    state: 'creditmanager',
    name: 'Credit Manager',
    type: 'sub',
    icon: 'credit_card',
    children: [
      { state: 'accountrecharge', name: 'Account Recharge', type: 'link' },
      { state: 'rechargereport', name: 'Recharge History', type: 'link' },
    ]
  },
  {
    state: 'mastermanager',
    name: 'Master Manager',
    type: 'sub',
    icon: 'desktop_windows',
    children: [
      { state: 'mastermanager', name: 'Branch Manager', type: 'link' },
    ]
  },
  {
    state: 'reportmanager',
    name: 'Report Manager',
    type: 'sub',
    icon: 'report',
    children: [
      { state: 'amazonreport', name: 'Amazon Deposit Report', type: 'link' },

      { state: 'billdeposit', name: 'Bill Deposit Report', type: 'link' },
      { state: 'billreversal', name: 'Bill Reversal Report', type: 'link' },
      { state: 'collection', name: 'Collection Report', type: 'link' },
      { state: 'creditreport', name: 'Credit Report', type: 'link' },
      { state: 'reversedreport', name: 'Reversed Bill Report', type: 'link' },
      { state: 'usercollection', name: 'User Collection Report', type: 'link' },
      { state: 'userwork', name: 'User Work Report', type: 'link' },

    ]
  },
  {
    state: 'usermanager',
    name: 'User Manager',
    type: 'sub',
    icon: 'supervised_user_circle',
    children: [
      { state: 'createuser', name: 'Create User', type: 'link' },
      { state: 'admintransfer', name: 'Agent Transfer', type: 'link' },
    ]
  },
  {
    state: 'workassign',
    name: 'Work Manager',
    type: 'sub',
    icon: 'work',
    children: [
      { state: 'workassign', name: 'Work Assignment Manager', type: 'link' },
      { state: 'workstatusreport', name: 'Work Status Report', type: 'link' },
      { state: 'mywork', name: 'My Work', type: 'link' },

    ]
  },
];



@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    if (localStorage.getItem("UserType") == "Super Admin" || localStorage.getItem("UserType") == "Manager") {
      return SuperAdmin;
    } else if (localStorage.getItem("UserType") == "Admin") {
      return Admin;
    } else if (localStorage.getItem("UserType") == "Agent") {
      return Agent;
    } else {
      return Depositor;
    }
    // return MENUITEMS;
  }
}
